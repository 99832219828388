import React, { useEffect, useState } from 'react';
import { Element, Events } from 'react-scroll';
import parse from 'html-react-parser';
import isUrl from 'is-url';
import { Citation, CitationDetail, RichCitation } from '../types';
import referenceStyles from './ArticleReferences.module.css';
import {
  calculateRatings,
  CitationRating,
} from '@xyla/openevidence-shared-functionality/src/article/rating-utils';
import { CITATION_LINK_SUFFIX } from '../citations';
import { getPlainCitationString } from '@xyla/openevidence-shared-functionality/src/article/citations';
import { Box, Button, Fade, SxProps, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Feed from '@mui/icons-material/Feed';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { FDAIcon } from './FDAIcon';
import { NIHIcon } from './NIHIcon';
import { isRichCitation } from '../utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../content/article_components/shared/accordion-components';
import { SnippetDataDisplay } from './SourceDetailHighlights';
import clsx from 'clsx';
import { useArticleContext } from '../contexts';
import {
  AnswerEngagedReferenceClickedEventProps,
  AnswerEngagedReferenceShowDetailsEventProps,
  AnswerEngagedReferencesListOpenedEventProps,
  AnswerEngagedReferencesListClosedEventProps,
} from '@xyla/analytics';
import { LocalizedText, whyCitedTypeToDisplayInfo } from '@xyla/util';
import { getLineClampSx } from '@xyla/style';
import { ReferenceFeedbackButtons } from './ReferenceFeedbackButtons';
import { PartialAccordion } from '../PartialAccordion';

const iconNameToIcon = (iconName: string) => {
  switch (iconName) {
    case 'star-outline':
      return StarOutlineIcon;
    case 'task-alt':
      return TaskAltIcon;
    case 'event':
      return EventIcon;
    case 'feed':
      return Feed;
    default:
      return undefined;
  }
};

const RatingBadge = ({ rating }: { rating: CitationRating }) => {
  const { customTags } = useArticleContext();
  const sx = {
    fontSize: '14px',
    transform: 'translateY(2px)',
  };

  const whyCitedDisplayInfo = whyCitedTypeToDisplayInfo(rating.type);
  if (!whyCitedDisplayInfo) {
    return null;
  }

  const Icon = iconNameToIcon(whyCitedDisplayInfo.iconName);
  if (!Icon) {
    return null;
  }

  const color = whyCitedDisplayInfo.color;

  const customTag =
    customTags && rating.label in customTags
      ? customTags[rating.label]
      : undefined;
  return (
    <Box
      sx={{
        color: color,
        display: 'inline-block',
        fontFamily: 'var(--oe-sans)',
        lineHeight: 1.5,
        mr: 1,
        whiteSpace: 'nowrap',
      }}
    >
      <Icon sx={sx} /> {customTag ? customTag : rating.label}
    </Box>
  );
};

const CustomBadge = ({
  label,
  color,
  Icon,
  className,
}: {
  label: string;
  color: string;
  Icon: React.ReactNode;
  className?: string;
}) => {
  // These badges are hidden in OE but have an override to display in Elsevier POC.
  // The `display:'none'` is overwritten to be `display:inline-block`
  return (
    <Box
      className={clsx('brandable--custom-badge', className)}
      sx={{
        display: 'none',
        fontFamily: 'var(--oe-sans)',
        lineHeight: 1.5,
        mr: 1.2,
        ml: 0.5,
        whiteSpace: 'nowrap',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          transform: 'translateY(5px)',
          mr: 0.5,
        }}
      >
        {Icon}
      </Box>
      <Box
        sx={{
          color: color,
          display: 'inline-block',
        }}
      >
        {label}
      </Box>
    </Box>
  );
};

interface RichReferenceProps {
  detail: CitationDetail;
  referenceNumber: number;
  spanSx?: SxProps;
}

const RichReference = ({
  detail,
  referenceNumber,
  spanSx,
}: RichReferenceProps) => {
  const { trackArticleEvent, transformReferenceLink } = useArticleContext();

  // Sometimes titles are extremely long with no spaces. This allows us to line-break on `/` if nec.
  const detailTitle = parse(detail.title.replace(/\//g, '/&shy;'));

  // 2 HACKS NEED TO REPLACE IMMEDIATELY: Do not show URL if derived_clinical_overview is in the URL
  const isDerivedClinicalOverview =
    detail.href && detail.href.includes('derived_clinical_overview');
  const isFDALabel = detail.href && detail.href.includes('dailymed');

  const isDetailLink = isUrl(detail.href) && !isFDALabel;
  const authorsString = isDerivedClinicalOverview
    ? 'Elsevier ClinicalKey'
    : detail.authors_string;

  let updatedDateString;

  if (detail.dt_last_updated) {
    const updatedDate = new Date(detail.dt_last_updated);
    updatedDateString = updatedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  const isClinicalKeyRepo = detail?.repository === 'ClinicalKey';
  const isOpenEvidenceRepo = detail?.repository === 'OpenEvidence';
  const isJournalArticle = detail?.publication_info_string?.includes('doi:');

  // Apply link transformation function if given
  const referenceLink = transformReferenceLink?.(detail) ?? detail.href;

  const trackReferenceClick = () => {
    trackArticleEvent?.('answer_engaged', {
      action: 'Reference clicked',
      reference_number: referenceNumber,
      reference_url: referenceLink,
      reference_doi: detail.doi,
    } satisfies AnswerEngagedReferenceClickedEventProps);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        hyphenateCharacter: '""',
      }}
    >
      <Box component='span' className={referenceStyles.title} sx={spanSx}>
        {isDetailLink ? (
          <a
            href={referenceLink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={trackReferenceClick}
          >
            {detailTitle}.
          </a>
        ) : isFDALabel ? (
          <>
            {detailTitle}.{' '}
            <a
              href={referenceLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={trackReferenceClick}
            >
              FDA Drug Label.
            </a>
          </>
        ) : (
          detailTitle
        )}
      </Box>
      <p className={referenceStyles.small_text}>{authorsString}</p>
      <p
        className={clsx(referenceStyles.small_text, referenceStyles.gray_text)}
      >
        {/* CK references get special formatting here. FDA labels sometimes don't come with publication info string so we can render dt_published which is the updated date. */}
        {isClinicalKeyRepo && !isJournalArticle && updatedDateString
          ? `${detail.publication_info_string}. Content last updated: ${updatedDateString}`
          : isFDALabel && !detail.publication_info_string
          ? `Updated date: ${detail?.dt_published}`
          : detail?.publication_info_string ?? ''}
      </p>
      {isClinicalKeyRepo && isJournalArticle && updatedDateString && (
        <p
          className={clsx(
            referenceStyles.small_text,
            referenceStyles.gray_text
          )}
        >
          {`Journal Article. Article last updated: ${updatedDateString}`}
        </p>
      )}
      {isOpenEvidenceRepo &&
        detail.source_article_links &&
        detail.source_article_links.length > 0 && (
          <>
            <p
              className={clsx(
                referenceStyles.small_text,
                referenceStyles.gray_text
              )}
            >
              {`TL;Dr. Summary of `}
              {detail.source_article_links?.map((link, index) => (
                <a
                  key={index}
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                  className={referenceStyles.gray_text}
                  style={{ overflowWrap: 'break-word' }}
                >
                  {link}
                </a>
              ))}
            </p>
          </>
        )}
    </Box>
  );
};

interface InnerRichReferencesProps {
  subCitations: RichCitation[];
  referenceNumber: number;
}

const InnerRichReferences = ({
  subCitations,
  referenceNumber,
}: InnerRichReferencesProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        hyphenateCharacter: '""',
        ml: { xs: 0, sm: 1 },
        my: 1.5,
        backgroundColor: '#f8f8f8',
        borderRadius: '10px',
        //border: "1px solid hwb(0 96% 10%)",
        px: 2,
        py: 1.3,
      }}
    >
      <Box>
        <Typography
          variant='subtitle2'
          sx={{
            fontWeight: 600,
          }}
        >
          Based on the following primary sources:
        </Typography>
      </Box>
      {subCitations.map((subCitation, i) => (
        <RichReference
          key={i}
          detail={subCitation.metadata.citation_detail}
          referenceNumber={referenceNumber}
        />
      ))}
    </Box>
  );
};

/** Displays one reference entry */
function Reference({
  reference,
  index,
  iconId,
}: {
  reference: Citation;
  index: number;
  iconId: string;
}) {
  const { trackArticleEvent, hideShowDetails } = useArticleContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    if (!isExpanded) {
      trackArticleEvent?.('answer_engaged', {
        action: 'Reference Show Details opened',
        reference_number: index + 1,
      } satisfies AnswerEngagedReferenceShowDetailsEventProps);
    }
    setIsExpanded(!isExpanded);
  };

  if (!isRichCitation(reference)) {
    const text = getPlainCitationString(reference);
    // Must render as span to get the correct styling (we are child of .text_wrapper)
    return <span>{parse(text)}</span>;
  }

  const scores = reference.metadata.why_cited;
  const ratings = calculateRatings(scores);

  // Make sure snippets with images are always displayed first.
  let snippetDisplayDataArray = reference.all_snippet_display_data ?? [];
  if (snippetDisplayDataArray.length > 1) {
    const reorderedArray = [];
    for (let snippet of reference.all_snippet_display_data!) {
      snippet.image_data
        ? reorderedArray.unshift(snippet)
        : reorderedArray.push(snippet);
    }
    snippetDisplayDataArray = reorderedArray;
  }
  const hasDataForSnippetProvenanceShow = snippetDisplayDataArray.length > 0;

  const detail = reference.metadata.citation_detail;
  const subCitations = reference.metadata.related_publications;

  return (
    // minWidth:0 prevents grid child from expanding the container
    <Box className={referenceStyles.reference} sx={{ minWidth: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          gap: 0.5,
        }}
      >
        {/* Reference content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            hyphenateCharacter: '""',
            gap: 0.4,
          }}
        >
          <RichReference
            detail={reference.metadata.citation_detail}
            referenceNumber={index + 1}
            spanSx={[!isExpanded && getLineClampSx()]}
          />

          {/* Ratings */}
          <Box>
            {detail.repository === 'FDA' && (
              <CustomBadge
                key='fda-badge'
                label='FDA'
                color='#007cba'
                Icon={<FDAIcon iconId={iconId} />}
              />
            )}
            {detail.repository === 'Medline' && (
              <CustomBadge
                key='medline-badge'
                label='National Library of Medicine'
                color='#326195'
                Icon={<NIHIcon iconId={iconId} />}
              />
            )}
            {ratings.map((rating) => (
              <RatingBadge key={rating.type} rating={rating} />
            ))}
          </Box>
        </Box>

        {/* Detail and vote buttons */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          {!hideShowDetails && hasDataForSnippetProvenanceShow && (
            <Button
              variant='outlined'
              color='inherit'
              onClick={toggleExpanded}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              sx={{
                borderColor: 'grey.600',
                color: 'grey.800',
                flex: '0 0 auto',
                px: 1,
                py: '2px',
                whiteSpace: 'nowrap',
                fontSize: '0.75rem',
              }}
              endIcon={
                <ExpandMoreIcon
                  sx={{
                    ml: -0.5,
                    fontSize: '16px !important',
                    transform: isExpanded ? 'rotate(180deg)' : '',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
              }
            >
              Details
            </Button>
          )}
          <ReferenceFeedbackButtons
            referenceIndex={index}
            show={hideShowDetails || isExpanded}
            buttonSx={{ color: 'grey.400' }}
          />
        </Box>
      </Box>

      {/* Sub-citations */}
      {subCitations && subCitations.length > 0 && (
        <InnerRichReferences
          subCitations={subCitations}
          referenceNumber={index + 1}
        />
      )}

      {/* Snippet text */}
      {hasDataForSnippetProvenanceShow && (
        <SnippetDataDisplay
          snippetDisplayDataArray={snippetDisplayDataArray}
          open={isExpanded}
        />
      )}
    </Box>
  );
}

interface ArticleReferencesProps {
  references: Citation[];
  styles: Record<string, string>;
  pageType: string;
  questionIndex?: number;
}

export default function ArticleReferences({
  references,
  styles,
  pageType,
  questionIndex,
}: ArticleReferencesProps): JSX.Element | null {
  const { trackArticleEvent, usePartialAccordion } = useArticleContext();
  const useSourcesListFormat = pageType === 'ask_oe_light';

  const [isExpanded, setIsExpanded] = useState(!usePartialAccordion); // partial accordion starts collapsed

  const handleChange = () => {
    if (!isExpanded) {
      trackArticleEvent?.('answer_engaged', {
        action: 'References list opened',
      } satisfies AnswerEngagedReferencesListOpenedEventProps);
    } else {
      trackArticleEvent?.('answer_engaged', {
        action: 'References list closed',
      } satisfies AnswerEngagedReferencesListClosedEventProps);
    }
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // expand the accordion if the user scrolls to a citation link
    Events.scrollEvent.register('begin', (to) => {
      if (to.endsWith(CITATION_LINK_SUFFIX)) {
        setIsExpanded(true);
      }
    });

    return () => Events.scrollEvent.remove('begin');
  }, []);

  if (!references || references.length === 0) {
    return null;
  }

  const accordionBody = (
    <AccordionDetails sx={{ pb: 2, color: '#424242' }}>
      {references.map((reference, i) => (
        <Element
          name={`${questionIndex}_${i + 1}${CITATION_LINK_SUFFIX}`}
          key={`${questionIndex}_${i + 1}${CITATION_LINK_SUFFIX}`}
        >
          <Fade in>
            <div className={styles.references}>
              {!useSourcesListFormat && (
                <span
                  className={`${styles.reference_idx} ${
                    isRichCitation(reference)
                      ? referenceStyles.citation_number
                      : ''
                  }`}
                >
                  {i + 1}.
                </span>
              )}
              <Reference
                reference={reference}
                index={i}
                iconId={`${questionIndex}_${i}`}
              />
            </div>
          </Fade>
        </Element>
      ))}
    </AccordionDetails>
  );

  return (
    <Element
      name='References'
      className={clsx('brandable--references', styles.references_container)}
    >
      {usePartialAccordion ? (
        <PartialAccordion
          isExpanded={isExpanded}
          setIsExpanded={handleChange}
          title='References'
          expandIcon={<ExpandMoreIcon />}
          leadingIcon={<FormatListNumberedIcon />}
        >
          {accordionBody}
        </PartialAccordion>
      ) : (
        <Accordion expanded={isExpanded} onChange={handleChange}>
          <Fade in>
            <div>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FormatListNumberedIcon />
                <Typography sx={{ ml: '16px', fontSize: '18px' }}>
                  {useSourcesListFormat ? (
                    <LocalizedText str='Sources' />
                  ) : (
                    <LocalizedText str='References' />
                  )}
                </Typography>
              </AccordionSummary>
            </div>
          </Fade>
          {accordionBody}
        </Accordion>
      )}
    </Element>
  );
}
